/* Module: S */
.inside {

    .main  {

        &::before {
            display: block;
        }

        &::after {
            display: block;
        }

        > .container-fluid {
            padding-bottom: pxToRem(85);
            padding-top:    pxToRem(75);
        }

        .content-wrapper--one-col {
            
            width: 100%;

            p,
            ol,
            ul {
                width: 80%;
            }

        }

        &.col-2 {

            .main-content-wrapper {
                -ms-flex-preferred-size: 66.66666667%;
                flex-basis:              66.66666667%;
                max-width:               66.66666667%;
            }

            .lhs-nav-wrapper {
                display: block;
            }

            .content-wrapper,
            .block-quote,
            .accordion,
            .table-responsive,
            .tabs,
            .faq {
                -ms-flex-preferred-size: 100%;
                flex-basis:              100%;
                max-width:               100%;
            }
        }
    }
}
