// NOTE: Please try to change tint/opacity of these colours instead of creating new variables:
// Use darken/lighten scss functions instead, e.g. color: lighten($teal, 10%);

// Brandmark colour palette
$black:       #000;
$dark-grey:   #ebebeb;
$grey:        #6f6f6f;
$light-grey:  #b3b3b3;
$medium-grey: #f2f2f2;
$white:       #fff;

$gov-blue:         #004c97; // Pantone 2945
$gov-blue-1:       #1b5faa; // Pantone 2935
$from-border-grey: #979797;

$grey-c:           $from-border-grey;
$medium-blue:      $gov-blue;

// ****************************************

// Corporate brand colour palette
$dark-blue:         #201647;
$dark-blue-green:   #00573f;
$jungle-green:      #007b4b;
$navy:              $dark-blue; // Pantone 2765
$purple-light:      #bcb8c7;
$purple:            #797391;
$smoke-black:       mix($black, $white, 86%);
$sun-yellow:        #f8e81c;
$teal:              #00b7bd; // Pantone 326
$teal-light:        #48c8cc;

// Sub-brands colour palette
$water-and-catchments:       #71c5e8; // Pantone 297
$climate-change:             #fdda24; // Pantone 115
$corporate-services:         #201547; // Pantone 2765
$corporate-services--hover:  #615c7d;
$energy:                     #0072ce; // Pantone 285
$environment:                #cedc00; // Pantone 381
$forest-fire:                #e57200; // Pantone 152
$forest-fire-mgmt:           $jungle-green;
$forest-fire-mgmt-dark:      $dark-blue-green;
$forest-fire-mgmt-highlight: $sun-yellow;
$forest-fire-mgmt-neutral:   $smoke-black;
$fishermans-bend:            #47c5de; 
$fishermans-bend-dark:       #00838e;
$fishermans-bend-black:      $smoke-black;
$fishermans-bend-neutral:    #1f272a;
$local-infrastructure:       #af272f; // Pantone 1805
$planning:                   #642667; // Pantone 260

$carousel-dots: #bababa;


