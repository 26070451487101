/* Module: S */
// 0. FOOTER
.footer {

    .container-fluid {
        max-width: pxToRem(1232);
    }
}

// 1. FOOTERMENU
.footer-menu {
    background: $corporate-services;
    color:      $white;
    padding:    pxToRem(44) 0;

    .footer__list {
        @include sq-list-reset();
        display:     none;
        font-weight: 600;
    }

    .footer__list--social {
        display:    block;
        padding:    0 0 pxToRem(44);
        text-align: center;
    }

    .footer__list--col {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        columns: 2;
    }

    .footer__link {
        color:           inherit;
        line-height:     2.6;
        text-decoration: none;

        &:hover,
        &:focus {
            outline:         none;
            text-decoration: underline;
        }
    }

    .footer__item--social {
        display:     inline-block;
        line-height: 0;
        margin:      0 pxToRem(16);
    }

    .footer__link--social {
        @include sq-transition(all .35s ease);
        background-position: center center;
        background-repeat:   no-repeat;
        background-size:     cover;
        display:             inline-block;
        height:              pxToRem(24);
        overflow:            hidden;
        text-indent:         100%;
        white-space:         nowrap;
        width:               pxToRem(24);
    }

    .footer__link--fb {
        background-image: url('{{file_dest}}/fb-logo.svg');
        background-size:  contain;

        &:hover,
        &:focus {
            outline:          none;
            background-image: url('{{file_dest}}/fb-logo-teal.svg');
        }
    }

    .footer__link--tw {
        background-image: url('{{file_dest}}/tw-logo.svg');

        &:hover,
        &:focus {
            outline:          none;
            background-image: url('{{file_dest}}/tw-logo-teal.svg');
        }
    }

    .footer__link--li {
        background-image: url('{{file_dest}}/li-logo.svg');

        &:hover,
        &:focus {
            outline:          none;
            background-image: url('{{file_dest}}/li-logo-teal.svg');
        }
    }

    .footer__link--yt {
        background-image: url('{{file_dest}}/yt-logo.svg');
        background-size:  contain;

        &:hover,
        &:focus {
            outline:          none;
            background-image: url('{{file_dest}}/yt-logo-teal.svg');
        }
    }

    .footer__link--ig {
        background-image: url('{{file_dest}}/ig-logo.svg');

        &:hover,
        &:focus {
            outline:          none;
            background-image: url('{{file_dest}}/ig-logo-teal.svg');
        }
    }

    .footer__heading {
        color:          $white;
        display:        none;
        font-size:      pxToRem(14);
        font-weight:    600;
        line-height:    2.6;
        margin:         0;
        text-transform: uppercase;
    }
}

// 2. QUICKCONTACT
.quick-contact {
    background:  $white;
    color:       $corporate-services;
    font-size:   pxToRem(12);
    line-height: 1.6;
    padding:     pxToRem(50) 0;
    text-align:  center;

    .quick-contact__list {
        @include sq-list-reset();
    }

    .quick-contact__item {
        display:    block;

        + .quick-contact__item {
            margin-top: pxToRem(16);
        }
    }

    .quick-contact__item--icon {

        &::before {
            background:      url('{{file_dest}}/phone.svg') no-repeat center center transparent;
            background-size: cover;
            content:         '';
            display:         inline-block;
            height:          pxToRem(14);
            margin:          0 pxToRem(10) 0 0;
            vertical-align:  text-bottom;
            width:           pxToRem(14);
        }
    }

    .quick-contact__info {
        color:           inherit;
        font-weight:     700;
        text-decoration: none;
        white-space:     nowrap;
    }

    a.quick-contact__info:hover,
    a.quick-contact__info:focus {
        outline:         none;
        text-decoration: underline;
    }

    br {
        display: none;
    }
}

// 3. FOOTERGOV
.footer-gov {
    color:  $white;
    margin: 0 auto;

    .footer-gov__logo {
        background-size: cover;
        display:         block;
        height:          pxToRem(56);
        margin:          0 auto;
        width:           pxToRem(98);

        &:hover,
        &:focus {
            outline: none;
        }
         // to fix accessibility issue from FB
         .sr-only {
            background: $corporate-services;
            color: $white;
        }
    }

    .footer-gov__copy {
        font-size:  pxToRem(10);
        margin:     pxToRem(16) 0;
        text-align: center;
    }
}

// Theme: Forest Fire Management
.forest-fire-management {

    .footer-menu {
        background-color: mix($black, $white, 86%);

        .footer__link--social {

            &:hover,
            &:focus {
                color: $forest-fire-mgmt-highlight;
            }

        }

        // Facebook
        .footer__link--fb {

            &:hover::before,
            &:focus::before {
                background-image: url('{{file_dest}}/social-facebook--sun-yellow.svg');
            }

        }

        // Instagram
        .footer__link--ig {

            &:hover::before,
            &:focus::before {
                background-image: url('{{file_dest}}/social-instagram--sun-yellow.svg');
            }

        }

        // LinkedIn
        .footer__link--li {

            &:hover::before,
            &:focus::before {
                background-image: url('{{file_dest}}/social-linkedin--sun-yellow.svg');
            }

        }

        // Twitter
        .footer__link--tw {

            &:hover::before,
            &:focus::before {
                background-image: url('{{file_dest}}/social-twitter--sun-yellow.svg');
            }

        }

        // YouTube
        .footer__link--yt {

            &:hover::before,
            &:focus::before {
                background-image: url('{{file_dest}}/social-youtube--sun-yellow.svg');
            }

        }

    }

    .quick-contact {
        color: $forest-fire-mgmt-neutral;
    }

}
// Theme: Fishermans Bend
.fishermans-bend {
    .footer {
        overflow: hidden;
    }
    .quick-contact {
        color: $fishermans-bend-black;
    }
    .footer-menu {
        background: $fishermans-bend-neutral;
        position:   relative;

        .col-md-2 {
            .footer__item {
                .footer__link {
                    color: #c9c9c9;
                }
                .footer__link--social {
                    
                    &:hover,
                    &:focus {
                        color: $teal;
                    }
                }
            }
        }
    }
}

//DJPR Styles moved from inline into project built as part of OMP-278
#footer{
  background: #000;
  color: #fff;
  padding-bottom: 10em;
}

.footer-logo{
  height: 100px;
  width: 100px;
}
    
#footer .container-fluid{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
    
    
@media screen and (min-width: 1024px){
  footer .footer-logo {
    float: right;    
  }
}
    
.footer-logo a{
  display: block;
  height: 100px;
  width:100px;
  margin-right: 0.8em;
} 
    
.utility-links{
  padding-top: 20px;
  display: inline-block;
}
    
.utility-links span:before {
  content: "|";
  margin: 0 1em 0 0;
}

.utility-links span:first-child:before {
    margin: 0 0 0 0;
}
footer .utility-links span a {
  margin-right: 0.8em;
  color: #fff;
  font-family: "VIC", Arial, sans-serif;
  font-size: 18px;
}

footer .utility-links span:first-child:before {
  content: "";
}

.copyright p:first-child{
  font-weight: 600;
  display: inline-block;
  margin-right: 1em;
  font-size: 18px;
}

.copyright p:last-child{
  display: inline-block;
  font-size: 18px;
}


/*social links */
.footer__link--li::before {
    background-image: url("./?a=61278");
    background-size: contain;
}

.footer__link--social::before {
    -webkit-transition: all 0.35s ease;
    -moz-transition: all 0.35s ease;
    transition: all 0.35s ease;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    display: inline-block;
    height: 1rem;
    margin-right: 0.5rem;
  /* vertical-align: middle; */
    width: 1rem;
}
.footer__link--social {
    background: 
    transparent;
    height: auto;
    overflow: visible;
    text-indent: 0;
    white-space: normal;
    width: auto;
}

.footer__link {
    color: inherit;
    line-height: 2.6;
    text-decoration: none;
}
.footer__link--fb::before {
    background-image: url("./?a=61279");
    background-size: contain;
}
.footer__list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-weight: 600;
    
}
.footer__list li {
display: inline-block;
}

.footer__list li:first-child {
    margin-right: 1em; 
}
