/* Module: S */
// 1. HEADER
.header {
    background-color: $navy;
    padding:          pxToRem(8) 0;
    position:         relative;
    z-index:          10;

    .header__logo {
        background:      url('{{file_dest}}/logo-copy.svg') no-repeat center center;
        background-size: cover;
        display:         block;
        height:          pxToRem(34);
        position:        relative;
        width:           pxToRem(117);
        z-index:         2;

        &:hover,
        &:focus {
            outline: none;
        }
        // to fix accessibility issue from FB
        .sr-only {
            background: $corporate-services;
            color: $white;
        }
    }

    .header__search-icon,
    .header__menu-burger {
        background:  transparent;
        border:      0;
        color:       inherit;
        cursor:      pointer;
        display:     inline-block;
        line-height: 0;
        margin:      0;
        padding:     pxToRem(8);
        position:    relative;
        z-index:     2;

        &:focus,
        &:hover {
            outline: none;
        }
        
        a {
            color: inherit;

            &:focus {
                outline: none;
            }
        }
    }

    .header__search-icon {
        @include sq-transition(opacity .30s ease);
        margin-right: pxToRem(32);
        opacity:      1;
        padding:      pxToRem(6);
    }

    .header__search-icon-box {
        background:      url('{{file_dest}}/search-icon--white.svg') no-repeat center center transparent;
        background-size: cover;
        display:  inline-block;
        height:   pxToRem(20);
        position: relative;
        width:    pxToRem(20);
    }

    .header__menu-burger--active {

        .header__menu-burger-inner {
            background: $teal;
            width:      0;

            &::after {
                @include sq-transform(rotate(-45deg) translate3d(pxToRem(3.5), pxToRem(-5), 0));
                width: pxToRem(22);
            }

            &::before {
                @include sq-transform(rotate(45deg) translate3d(pxToRem(3.5), pxToRem(4.5), 0));
                width: pxToRem(22);
            }
        }
    }

    .header__menu-burger-box {
        display:  inline-block;
        height:   pxToRem(14);
        position: relative;
        width:    pxToRem(20);
    }

    .header__menu-burger-inner {
        @include sq-transition(all .35s ease);
        background: $navy;
        display:    block;
        height:     pxToRem(2);
        position:   absolute;
        top:        pxToRem(6);
        width:      pxToRem(20);

        &::before,
        &::after {
            @include sq-transition(all .15s ease);
            background: $navy;
            content:    '';
            display:    block;
            height:     pxToRem(2);
            position:   absolute;
        }

        &::before {
            bottom: pxToRem(6);
            width:  pxToRem(20);
        }

        &::after {
            top:   pxToRem(6);
            width: pxToRem(15);
        }
    }

    .header__menu-text {
        display: none;
        margin:  0 0 0 pxToRem(20);
    }

    .header__curves {
        bottom:   0;
        left:     0;
        overflow: hidden;
        position: absolute;
        right:    0;
        top:      0;

        &::before {
            border-color: transparent transparent $teal transparent;
            border-style: solid;
            border-width: 0 pxToRem(200) pxToRem(420) pxToRem(200);
            content:      '';
            height:       100%;
            position:     absolute;
            right:        pxToRem(-330);
            top:          pxToRem(-370);
        }
    }

    .utility--minimal {
        .header__search-form,
        .header__search-icon {
            display: none;
        }
    }
}

// 2. SEARCH
.header__search-form {
    display: none;
    z-index: 2;
}

.header__search-mobile {
    background: $teal;
    display:    none;
    left:       0;
    // overflow:   hidden;
    position:   absolute;
    right:      0;
    top:        pxToRem(50);

    .header__search-form {
        display: block;
        padding: pxToRem(8) 0;
    }

    .header__search-label {
        color:       $white;
        font-weight: 500;
    }

    .header__search-input {
        background: $white;
        color:      $fishermans-bend-black;
        border:     0;
        height:     pxToRem(34);
        margin:     0;
        padding:    0 pxToRem(4);
        width:      100%;
    }

    .header__search-submit {
        display: none;
    }
}

// 3. NAVIGATION LEFT
.navigation {
    &::before {
        background: $teal;
        content:    '';
        display:    block;
        height:     pxToRem(50);
        width:      100%;
    }

    &.slideout-menu {
        -webkit-overflow-scrolling: touch;
        bottom:     0;
        display:    none;
        left:       auto;
        overflow-y: auto;
        position:   fixed;
        right:      0;
        top:        0;
        width:      268px;
        z-index:    0;
    }

    .slideout-open &.slideout-menu {
        display: block;
    }

    .navigation__list,
    .navigation__sublist-l2,
    .navigation__sublist-l3,
    .navigation__sublist-l4 {
        @include sq-list-reset();
        background: $white;
    }

    .navigation__sublist-l2,
    .navigation__sublist-l3,
    .navigation__sublist-l4 {
        display: none;
    }

    .navigation__item,
    .navigation__sub-l2-item,
    .navigation__sub-l3-item {
        font-size:     pxToRem(18);
        font-weight:   600;
        position:      relative;
    }

    .navigation__link,
    .navigation__sub-l2-link,
    .navigation__sub-l3-link,
    .navigation__sub-l4-link {
        @include sq-transition(all .35s ease);
        color:           $corporate-services;
        display:         block;
        padding:         pxToRem(13) pxToRem(64) pxToRem(12) pxToRem(14);
        position:        relative;
        text-decoration: none;
        word-break:      break-word;
    }

    .navigation__link {

        &::after {
            background: rgba($navy, .3);
            bottom:     0;
            content:    '';
            height:     pxToRem(1);
            left:       0;
            margin:     0 pxToRem(14);
            position:   absolute;
            width:      calc(100% - 28px);
        }

        &:hover,
        &:focus {
            outline:    none;
            background: lighten($black, 95%);
        }
    }

    .navigation__cta {
        @include sq-transition(all .35s ease);
        background: url('{{file_dest}}/chevron--navy.svg')no-repeat center center transparent;
        border:     0;
        height:     pxToRem(50);
        outline:    transparent dotted thin;
        padding:    0;
        position:   absolute;
        right:      pxToRem(14);
        top:        0;
        width:      pxToRem(50);

        &:hover,
        &:focus {
            outline: none;
            // outline: rgba($corporate-services, .7) dotted thin;
        }
    }

    .navigation__cta--active {
        @include sq-transform(rotate(180deg));
    }

    .navigation__sub-l2-link {
        padding: pxToRem(13) pxToRem(64) pxToRem(13) pxToRem(14);
        background: lighten($black, 95%);

        &:hover,
        &:focus {
            background: lighten($black, 85%);
        }
    }

    .navigation__sub-l3-link {
        padding: pxToRem(13) pxToRem(64) pxToRem(13) pxToRem(29);
        background: lighten($black, 85%);

        &:hover,
        &:focus {
            outline:    none;
            background: rgba($navy, .3);
        }
    }

    .navigation__sub-l4-link {
        padding: pxToRem(13) pxToRem(30) pxToRem(13) pxToRem(54);
        background: rgba($navy, .3);

        &:hover,
        &:focus {
            outline:    none;
            background: rgba($navy, .5);
        }
    }
}

// 4. MEGAMENU
.megamenu {
    display: none;
    z-index: 2;

    .megamenu__list {
        @include sq-list-reset();
        display:  block;
    }

    .megamenu__item {
        @include sq-list-reset();
        display: inline-block;
    }

    .megamenu__link {
        border:          pxToRem(1) solid transparent;
        color:           $white;
        display:         inline-block;
        font-weight:     400;
        position:        relative;
        text-decoration: none;

        &::after {
            @include sq-transition(transform .35s ease);
            background:      url('{{file_dest}}/chevron.svg') no-repeat center center;
            background-size: cover;
            content:         '';
            display:         block;
            position:        absolute;
        }

        &::before {
            @include sq-transition(all .35s ease);
            background: $teal;
            bottom:     0;
            content:    '';
            display:    block;
            height:     pxToRem(3);
            opacity:    0;
            position:   absolute;
            width:      100%;
        }

        &:hover,
        &:focus {
            outline: none;

            &::before {
                opacity: 1;
            }
        }

    }

    .megamenu__link--open {

        &::after {
            @include sq-transform(rotate(180deg));
        }

        &::before {
            opacity:    1;
        }
    }

    .megamenu__sub-nav {
        @include sq-transition(all .25s ease);
        display:          block;
        left:             0;
        opacity:          0;
        position:         absolute;
        right:            0;
        visibility:       hidden;
        z-index:          5;

        &.megamenu__link--open {
            @include sq-transition(all .35s ease .35s);
            display:    block;
            opacity:    1;
            visibility: visible;
        }
    }

    .megamenu__sub-group {
        display:        block;
        margin:         0;
        padding:        pxToRem(28) 0 pxToRem(44);
        vertical-align: top;
    }

    .megamenu__sub-item {
        @include sq-list-reset();
        border-bottom: pxToRem(2) solid lighten($black, 80%);
        display:       block;
        width:         100%;
    }

    .megamenu__sub-link {
        color:           $corporate-services;
        display:         block;
        font-weight:     600;
        min-height:      pxToRem(67);
        padding:         pxToRem(19) 0 pxToRem(17);
        position:        relative;
        text-decoration: none;

        &:hover,
        &:focus {
            outline: none;
            color:   $corporate-services--hover
        }

        .megamenu__sub-thumb {
            display:        block;
            height:         pxToRem(29);
            left:           0;
            margin-right:   pxToRem(18);
            position:       absolute;
            top:            pxToRem(19);
            vertical-align: middle;
            width:          pxToRem(29);
        }

        span {
            display:     inline-block;
            padding-top: pxToRem(4);
        }

        .megamenu__sub-thumb + span {
            margin-left: pxToRem(49);
        }
    }

    .megamenu__sub-detail {
        margin-top: 0;

        a {
            color:           $corporate-services;
            text-decoration: none;
        }
    }

    .megamenu__cta-wrapper {
        background: lighten($black, 80%);
        height:     pxToRem(50);
        text-align: right;
    }

    .megamenu__cta {
        color:           $navy;
        display:         inline-block;
        font-weight:     600;
        padding:         pxToRem(13) pxToRem(34) pxToRem(15);
        text-decoration: none;

        &::after {
            background:      url({{file_dest}}/arrow.svg) no-repeat center center;
            background-size: cover;
            content:         '';
            display:         inline-block;
            height:          pxToRem(13);
            margin-left:     pxToRem(19);
            vertical-align:  middle;
            width:           pxToRem(27);
        }
    }

    .block-tile {
        background: none;
        margin:     0 auto;
        max-width:  pxToRem(1198);
        padding:    pxToRem(30) 0;
        &__item {
            margin-bottom: 0;
        }
    }
}

.megamenu__overlay {
    @include sq-transition(height .35s ease);
    background: $white;
    height:     0;
    left:       0;
    position:   absolute;
    right:      0;
    top:        pxToRem(50);
}
.megamenu__locations {
    .megamenu-locations-editable-text {
        margin: pxToRem(40) 0 0;
    }
}

// 5. UTILITY LINKS ON NAVIGATION LEFT
.utility {
    &__list {
        list-style-type: none;
        padding:         pxToRem(10) pxToRem(14);
    }

    &__link {
        color:           $corporate-services;
        line-height:     pxToRem(25);
        text-decoration: none;
        
        &:focus {
            outline: none;
        }
        
        &:hover, &:active {
            color: $corporate-services--hover;
        }
    }
}

.no-js {
    display:   -webkit-box;
    display:   -moz-box;
    display:   -ms-flexbox;
    display:   -webkit-flex;
    display:   flex;
    flex-flow: column;

    .navigation {
        order: 2;

        .navigation__sublist-l2,
        .navigation__sublist-l3,
        .navigation__sublist-l4 {
            display: block;
        }
    }
}

// Theme: Forest Fire Management
.forest-fire-management {

    .header {
        background-color: $forest-fire-mgmt-neutral;

        &__search-mobile,
        &__search-label,
        &__search-input {
            color: $forest-fire-mgmt-neutral;
        }

        &__search-mobile {
            background-color: $forest-fire-mgmt-highlight;
        }

        .header__curves::before {
            border-bottom-color: $forest-fire-mgmt-highlight;
        }

    }


    .megamenu__link {
        &::before {
            background: $sun-yellow;
        }
    }

    .navigation {

        &::before {
            background-color: $forest-fire-mgmt-highlight;
        }

        .navigation__link,
        .navigation__sub-l2-link,
        .navigation__sub-l3-link,
        .navigation__sub-l4-link {
            color: $forest-fire-mgmt-neutral;
        }

        .navigation__sub-l2-link {
            background-color: mix($black, $white, 7%);
        }

        .navigation__sub-l2-link:hover,
        .navigation__sub-l2-link:focus,
        .navigation__sub-l3-link {
            background-color: mix($black, $white, 15%);
        }

        .navigation__sub-l3-link:hover,
        .navigation__sub-l3-link:focus,
        .navigation__sub-l4-link {
            background-color: mix($forest-fire-mgmt, $white, 43%);
        }

        .navigation__sub-l4-link:hover,
        .navigation__sub-l4-link:focus {
            background-color: mix($forest-fire-mgmt, $white, 53%);
        }

    }

    .utility {

        &__link,
        &__link:hover,
        &__link:focus {
            color: $forest-fire-mgmt-neutral;
        }

    }

}

// Theme: Fishermans Bend
.fishermans-bend {
    .header {
        background-color: $fishermans-bend-neutral;

        &__search-mobile,
        &__search-label,
        &__search-input {
            color: $fishermans-bend-black;
        }

        &__search-mobile {
            background-color: $fishermans-bend;
        }

        .header__curves::before {
            border-bottom-color: $fishermans-bend;
        }
    }

    .megamenu__link {
        &::before {
            background: $fishermans-bend;
        }
    }
    .navigation {

        &::before {
            background-color: $fishermans-bend;
        }

        .navigation__link,
        .navigation__sub-l2-link,
        .navigation__sub-l3-link,
        .navigation__sub-l4-link {
            color: $fishermans-bend-black;
        }

        .navigation__sub-l2-link {
            background-color: mix($black, $white, 7%);
        }

        .navigation__sub-l2-link:hover,
        .navigation__sub-l2-link:focus,
        .navigation__sub-l3-link {
            background-color: mix($black, $white, 15%);
        }

        .navigation__sub-l3-link:hover,
        .navigation__sub-l3-link:focus,
        .navigation__sub-l4-link {
            background-color: mix($fishermans-bend, $white, 43%);
        }

        .navigation__sub-l4-link:hover,
        .navigation__sub-l4-link:focus {
            background-color: mix($fishermans-bend, $white, 53%);
        }
    }

    .utility {

        &__link,
        &__link:hover,
        &__link:focus {
            color: $fishermans-bend-black;
        }

    }
}
